<template>
  <!--  Body Wrapper -->
  <div
    class="page-wrapper"
    id="main-wrapper"
    data-theme="blue_theme"
    data-layout="vertical"
    data-sidebartype="full"
    data-sidebar-position="fixed"
    data-header-position="fixed"
  >
    <!-- Sidebar Start -->
    <aside class="left-sidebar">
      <!-- Sidebar scroll-->
      <div>
        <div
          class="brand-logo d-flex align-items-center justify-content-between"
        >
          <a href="/" class="text-nowrap logo-img">
            <img
              src="dist/images/logos/light-logo.svg"
              class="light-logo"
              width="48"
              alt=""
              style="margin-left: 50px; margin-top: 50px"
            />
          </a>
          <div
            class="close-btn d-lg-none d-block sidebartoggler cursor-pointer"
            id="sidebarCollapse"
          >
            <i class="ti ti-x fs-8 text-muted"></i>
          </div>
        </div>
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav scroll-sidebar" data-simplebar>
          <ul id="sidebarnav">
            <!-- ============================= -->
            <!-- Home -->
            <!-- ============================= -->
            <li class="nav-small-cap">
              <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span class="hide-menu">Activity</span>
            </li>
            <!-- =================== -->
            <!-- Dashboard -->
            <!-- =================== -->
            <li class="sidebar-item">
              <a class="sidebar-link" href="/dashboard" aria-expanded="false">
                <span>
                  <i class="ti ti-home"></i>
                </span>
                <span class="hide-menu">Dashboard</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a class="sidebar-link" href="/reports" aria-expanded="false">
                <span>
                  <i class="ti ti-report"></i>
                </span>
                <span class="hide-menu">Reports</span>
              </a>
            </li>
            <!-- ============================= -->
            <!-- Apps -->
            <!-- ============================= -->
            <li class="nav-small-cap">
              <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span class="hide-menu">Sales</span>
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link"
                href="/orders"
                aria-expanded="false"
                title="point of sales"
              >
                <span>
                  <i class="ti ti-shopping-cart"></i>
                </span>
                <span class="hide-menu">Orders</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link"
                href="/customer-parties"
                aria-expanded="false"
                title="customers"
              >
                <span>
                  <i class="ti ti-user-heart"></i>
                </span>
                <span class="hide-menu">Customers</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a class="sidebar-link" href="/proforma" aria-expanded="false">
                <span>
                  <i class="ti ti-quote"></i>
                </span>
                <span class="hide-menu">Quotations</span>
              </a>
            </li>
            <li class="nav-small-cap">
              <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span class="hide-menu">Stock</span>
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link"
                href="/stock-history"
                aria-expanded="false"
              >
                <span>
                  <i class="ti ti-house"></i>
                </span>
                <span class="hide-menu">Stock History</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a class="sidebar-link" href="/products" aria-expanded="false">
                <span>
                  <i class="ti ti-shopping-bag"></i>
                </span>
                <span class="hide-menu">Products</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link"
                href="/supplier-parties"
                aria-expanded="false"
              >
                <span>
                  <i class="ti ti-truck-delivery"></i>
                </span>
                <span class="hide-menu">Suppliers</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link"
                href="/purchases"
                aria-expanded="false"
              >
                <span>
                  <i class="ti ti-file-text"></i>
                </span>
                <span class="hide-menu">Purchase<i class="badge rounded-pill bg-danger fs-2" style="margin-left: 25px;">{{ countCartPurchase }}</i></span>
              </a>
            </li>
            <li class="sidebar-item">
              <a class="sidebar-link" href="/market" aria-expanded="false">
                <span>
                  <i class="ti ti-building-store"></i>
                </span>
                <span class="hide-menu">Market</span>
              </a>
            </li>
            <!-- ============================= -->
            <!-- PAGES -->
            <!-- ============================= -->
            <li class="nav-small-cap">
              <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span class="hide-menu">Account</span>
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link"
                href="/payments"
                aria-expanded="false"
                title="Payment Received"
              >
                <span>
                  <i class="ti ti-coin"></i>
                </span>
                <span class="hide-menu">Payments</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link"
                href="/finances"
                aria-expanded="false"
                title="finance"
              >
                <span>
                  <i class="ti ti-pig-money"></i>
                </span>
                <span class="hide-menu">Finances</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a
                class="sidebar-link"
                href="/expenses"
                aria-expanded="false"
                title="Company Expenses"
              >
                <span>
                  <i class="ti ti-wallet"></i>
                </span>
                <span class="hide-menu">Expenses</span>
              </a>
            </li>
            <!-- ============================= -->
            <!-- UI -->
            <!-- ============================= -->
            <li class="nav-small-cap">
              <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span class="hide-menu">Admin</span>
            </li>
            <li class="sidebar-item">
              <a class="sidebar-link" href="/website" aria-expanded="false">
                <span>
                  <i class="ti ti-at"></i>
                </span>
                <span class="hide-menu">Website</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a class="sidebar-link" href="/settings" aria-expanded="false">
                <span>
                  <i class="ti ti-settings"></i>
                </span>
                <span class="hide-menu">Settings</span>
              </a>
            </li>
          </ul>
          <div
            class="hide-menu bg-light-primary position-relative my-7 rounded"
          ></div>
        </nav>
        <div
          class="fixed-profile p-3 bg-light-secondary rounded sidebar-ad mt-3"
        >
          <div class="hstack gap-3">
            <div class="john-img">
              <img
                src="dist/images/profile/user-1.jpg"
                class="rounded-circle"
                width="40"
                height="40"
                alt=""
              />
            </div>
            <div class="john-title">
              <h6 class="mb-0 fs-4 fw-semibold">Mathew</h6>
              <span class="fs-2 text-dark">Designer</span>
            </div>
            <button
              class="border-0 bg-transparent text-primary ms-auto"
              tabindex="0"
              type="button"
              aria-label="logout"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-title="logout"
            >
              <i class="ti ti-power fs-6"></i>
            </button>
          </div>
        </div>
        <!-- End Sidebar navigation -->
      </div>
      <!-- End Sidebar scroll-->
    </aside>
    <!--  Sidebar End -->
    <!--  Main wrapper -->
    <div class="body-wrapper">
      <!--  Header Start -->
      <header class="app-header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link sidebartoggler nav-icon-hover ms-n3"
                id="headerCollapse"
                href="javascript:void(0)"
              >
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
            <li class="nav-item d-none d-lg-block">
              <a
                class="nav-link nav-icon-hover"
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <i class="ti ti-search"></i>
              </a>
            </li>
          </ul>
          <div class="btn-group p-1">
            <a href="/sales" class="btn btn-primary"> Point Of Sale </a>
          </div>
          <ul class="navbar-nav quick-links d-none d-lg-flex">
            <li class="nav-item dropdown hover-dd d-none d-lg-block">
              <a
                class="nav-link"
                href="javascript:void(0)"
                data-bs-toggle="dropdown"
                >Apps<span class="mt-1"><i class="ti ti-chevron-down"></i></span
              ></a>
              <div
                class="dropdown-menu dropdown-menu-nav dropdown-menu-animate-up py-0"
              >
                <div class="row">
                  <div class="col-8">
                    <div class="ps-7 pt-7">
                      <div class="border-bottom">
                        <div class="row">
                          <div class="col-6">
                            <div class="position-relative">
                              <a
                                href="/customer-parties"
                                class="d-flex align-items-center pb-9 position-relative"
                              >
                                <div
                                  class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                                >
                                  <img
                                    src="dist/images/svgs/icon-dd-chat.svg"
                                    alt=""
                                    class="img-fluid"
                                    width="24"
                                    height="24"
                                  />
                                </div>
                                <div class="d-inline-block">
                                  <h6 class="mb-1 fw-semibold bg-hover-primary">
                                    Customers
                                  </h6>
                                  <span class="fs-2 d-block text-dark"
                                    >Manage Cutomers</span
                                  >
                                </div>
                              </a>
                              <a
                                href="/orders"
                                class="d-flex align-items-center pb-9 position-relative"
                              >
                                <div
                                  class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                                >
                                  <img
                                    src="dist/images/svgs/icon-dd-invoice.svg"
                                    alt=""
                                    class="img-fluid"
                                    width="24"
                                    height="24"
                                  />
                                </div>
                                <div class="d-inline-block">
                                  <h6 class="mb-1 fw-semibold bg-hover-primary">
                                    Sales
                                  </h6>
                                  <span class="fs-2 d-block text-dark"
                                    >Get latest invoice</span
                                  >
                                </div>
                              </a>
                              <a
                                href="/finances"
                                class="d-flex align-items-center pb-9 position-relative"
                              >
                                <div
                                  class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                                >
                                  <img
                                    src="dist/images/svgs/icon-dd-mobile.svg"
                                    alt=""
                                    class="img-fluid"
                                    width="24"
                                    height="24"
                                  />
                                </div>
                                <div class="d-inline-block">
                                  <h6 class="mb-1 fw-semibold bg-hover-primary">
                                    Accounts
                                  </h6>
                                  <span class="fs-2 d-block text-dark"
                                    >Accounts Wallet History</span
                                  >
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="position-relative">
                              <a
                                href="/products"
                                class="d-flex align-items-center pb-9 position-relative"
                              >
                                <div
                                  class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                                >
                                  <img
                                    src="dist/images/svgs/icon-dd-cart.svg"
                                    alt=""
                                    class="img-fluid"
                                    width="24"
                                    height="24"
                                  />
                                </div>
                                <div class="d-inline-block">
                                  <h6 class="mb-1 fw-semibold bg-hover-primary">
                                    Products
                                  </h6>
                                  <span class="fs-2 d-block text-dark"
                                    >learn more information</span
                                  >
                                </div>
                              </a>
                              <a
                                href="/reports"
                                class="d-flex align-items-center pb-9 position-relative"
                              >
                                <div
                                  class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                                >
                                  <img
                                    src="dist/images/svgs/icon-dd-date.svg"
                                    alt=""
                                    class="img-fluid"
                                    width="24"
                                    height="24"
                                  />
                                </div>
                                <div class="d-inline-block">
                                  <h6 class="mb-1 fw-semibold bg-hover-primary">
                                    Reports
                                  </h6>
                                  <span class="fs-2 d-block text-dark"
                                    >Get dates</span
                                  >
                                </div>
                              </a>
                              <a
                                href="/purchases"
                                class="d-flex align-items-center pb-9 position-relative"
                              >
                                <div
                                  class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                                >
                                  <img
                                    src="dist/images/svgs/icon-dd-lifebuoy.svg"
                                    alt=""
                                    class="img-fluid"
                                    width="24"
                                    height="24"
                                  />
                                </div>
                                <div class="d-inline-block">
                                  <h6 class="mb-1 fw-semibold bg-hover-primary">
                                    Stock
                                  </h6>
                                  <span class="fs-2 d-block text-dark"
                                    >Add new Stock</span
                                  >
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center py-3">
                        <div class="col-8">
                          <a
                            class="fw-semibold text-dark d-flex align-items-center lh-1"
                            href="#"
                            ><i class="ti ti-help fs-6 me-2"></i>Frequently
                            Asked Questions</a
                          >
                        </div>
                        <div class="col-4">
                          <div class="d-flex justify-content-end pe-4">
                            <button class="btn btn-primary">Check</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 ms-n4">
                    <div class="position-relative p-7 border-start h-100">
                      <h5 class="fs-5 mb-9 fw-semibold">Quick Actions</h5>
                      <ul class="">
                        <li class="mb-3">
                          <a
                            class="fw-semibold text-dark bg-hover-primary"
                            href="/sales"
                            >Point of Sales</a
                          >
                        </li>
                        <li class="mb-3">
                          <a
                            class="fw-semibold text-dark bg-hover-primary"
                            href="/proforma"
                            >Quotations</a
                          >
                        </li>
                        <li class="mb-3">
                          <a
                            class="fw-semibold text-dark bg-hover-primary"
                            href="/expenses"
                            >New Expenses</a
                          >
                        </li>
                        <li class="mb-3">
                          <a
                            class="fw-semibold text-dark bg-hover-primary"
                            href="/purchases"
                            >New Purchase</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <button
            class="navbar-toggler p-0 border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="p-2">
              <i class="ti ti-dots fs-7"></i>
            </span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <div class="d-flex align-items-center justify-content-between">
              <a
                href="javascript:void(0)"
                class="nav-link d-flex d-lg-none align-items-center justify-content-center"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#mobilenavbar"
                aria-controls="offcanvasWithBothOptions"
              >
                <i class="ti ti-align-justified fs-7"></i>
              </a>
              <div class="btn-group p-1">
                <ul class="navbar-nav quick-links d-none d-lg-flex">
                  <li class="nav-item dropdown hover-dd d-none d-lg-block">
                    <a
                      class="nav-link"
                      href="javascript:void(0)"
                      data-bs-toggle="dropdown"
                      >{{ selectedWarehouseName
                      }}<span class="mt-1"
                        ><i class="ti ti-chevron-down"></i>
                        <p hidden>{{ selectedWarehouse }}</p></span
                      ></a
                    >
                    <div
                      class="dropdown-menu dropdown-menu-nav dropdown-menu-animate-up py-0"
                    >
                      <div class="row">
                        <div class="col-8">
                          <div class="ps-7 pt-7">
                            <div class="border-bottom">
                              <div class="row">
                                <div class="col-6">
                                  <div class="position-relative">
                                    <a
                                      href="#"
                                      class="d-flex align-items-center pb-9 position-relative"
                                      v-for="(warehouse, key) in warehouses"
                                      :key="key"
                                      @click="setDefaultBranch(warehouse.id)"
                                    >
                                      <div class="d-inline-block">
                                        <h6
                                          class="mb-1 fw-semibold bg-hover-primary"
                                        >
                                          {{ warehouse.name
                                          }}<span hidden>{{
                                            warehouse.id
                                          }}</span>
                                        </h6>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <ul
                class="navbar-nav flex-row ms-auto align-items-center justify-content-center"
              >
                <li class="nav-item dropdown">
                  <a
                    class="nav-link nav-icon-hover"
                    href="javascript:void(0)"
                    id="drop2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="dist/images/svgs/icon-flag-en.svg"
                      alt=""
                      class="rounded-circle object-fit-cover round-20"
                    />
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop2"
                  >
                    <div class="message-body" data-simplebar>
                      <a
                        href="javascript:void(0)"
                        class="d-flex align-items-center gap-2 py-3 px-4 dropdown-item"
                      >
                        <div class="position-relative">
                          <img
                            src="dist/images/svgs/icon-flag-en.svg"
                            alt=""
                            class="rounded-circle object-fit-cover round-20"
                          />
                        </div>
                        <p class="mb-0 fs-3">English (UK)</p>
                      </a>
                    </div>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link notify-badge nav-icon-hover" href="javascript:void(0)" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <i class="ti ti-basket"></i>
                    <span class="badge rounded-pill bg-danger fs-2">{{ countCartSales }}</span>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link nav-icon-hover"
                    href="javascript:void(0)"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="ti ti-bell-ringing"></i>
                    <div class="notification bg-primary rounded-circle"></div>
                  </a>
                  <div
                    class="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop2"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between py-3 px-7"
                    >
                      <h5 class="mb-0 fs-5 fw-semibold">Notifications</h5>
                      <!-- <span class="badge bg-primary rounded-4 px-3 py-1 lh-sm">5 new</span> -->
                    </div>
                    <div class="message-body" data-simplebar>
                      <a
                        href="javascript:void(0)"
                        class="py-6 px-7 d-flex align-items-center dropdown-item"
                      >
                        <span class="me-3">
                          <img
                            src="dist/images/logos/light-logo.svg"
                            alt=""
                            class="img-fluid mb-n4"
                          />
                        </span>
                        <div class="w-75 d-inline-block v-middle">
                          <h6 class="mb-1 fw-semibold">
                            Thank you for joining Zata Global!
                          </h6>
                          <span class="d-block">Congratulation</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link pe-0"
                    href="javascript:void(0)"
                    id="drop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div class="d-flex align-items-center">
                      <div class="user-profile-img">
                        <img
                          :src="profileImage"
                          class="rounded-circle"
                          width="60"
                          height="60"
                          alt=""
                        />
                      </div>
                    </div>
                  </a>
                  <div
                    class="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop1"
                  >
                    <div
                      class="profile-dropdown position-relative"
                      data-simplebar
                    >
                      <div
                        class="d-flex align-items-center py-9 mx-7 border-bottom"
                      >
                        <img
                          :src="profileImage"
                          class="rounded-circle"
                          width="80"
                          height="80"
                          alt=""
                        />
                        <div class="ms-3">
                          <h5 class="mb-1 fs-3">{{ profileName }}</h5>
                          <span class="mb-1 d-block text-dark">{{
                            profileVerified
                          }}</span>
                          <p
                            class="mb-0 d-flex text-dark align-items-center gap-2"
                          >
                            <i class="ti ti-mail fs-4"></i> {{ profileEmail }}
                          </p>
                        </div>
                      </div>
                      <div class="message-body">
                        <a
                          href="/profile"
                          class="py-8 px-7 mt-8 d-flex align-items-center"
                        >
                          <span
                            class="d-flex align-items-center justify-content-center bg-light rounded-1 p-6"
                          >
                            <img
                              src="dist/images/svgs/icon-account.svg"
                              alt=""
                              width="24"
                              height="24"
                            />
                          </span>
                          <div class="w-75 d-inline-block v-middle ps-3">
                            <h6 class="mb-1 text-primary fw-semibold">
                              My Profile
                            </h6>
                            <span class="d-block text-dark"
                              >Account Settings</span
                            >
                          </div>
                        </a>
                        <a
                          href="/settings"
                          class="py-8 px-7 d-flex align-items-center"
                        >
                          <span
                            class="d-flex align-items-center justify-content-center bg-light rounded-1 p-6"
                          >
                            <img
                              src="dist/images/svgs/icon-inbox.svg"
                              alt=""
                              width="24"
                              height="24"
                            />
                          </span>
                          <div class="w-75 d-inline-block v-middle ps-3">
                            <h6 class="mb-1 text-primary fw-semibold">
                              Company
                            </h6>
                            <span class="d-block text-dark"
                              >Company Settings</span
                            >
                          </div>
                        </a>
                        <a
                          href="/companies"
                          class="py-8 px-7 d-flex align-items-center"
                        >
                          <span
                            class="d-flex align-items-center justify-content-center bg-light rounded-1 p-6"
                          >
                            <img
                              src="dist/images/svgs/icon-inbox.svg"
                              alt=""
                              width="24"
                              height="24"
                            />
                          </span>
                          <div class="w-75 d-inline-block v-middle ps-3">
                            <h6 class="mb-1 text-primary fw-semibold">
                              Switch Company
                            </h6>
                            <span class="d-block text-dark"
                              >Swith to Another Company</span
                            >
                          </div>
                        </a>
                        <a href="#" class="py-8 px-7 d-flex align-items-center">
                          <span
                            class="d-flex align-items-center justify-content-center bg-light rounded-1 p-6"
                          >
                            <img
                              src="dist/images/svgs/icon-tasks.svg"
                              alt=""
                              width="24"
                              height="24"
                            />
                          </span>
                          <div class="w-75 d-inline-block v-middle ps-3">
                            <h6 class="mb-1 text-primary fw-semibold">
                              Finance
                            </h6>
                            <span class="d-block text-dark"
                              >Loans & Insurance</span
                            >
                          </div>
                        </a>
                        <a
                          href="/refer-a-friend"
                          class="py-8 px-7 d-flex align-items-center"
                        >
                          <span
                            class="d-flex align-items-center justify-content-center bg-light rounded-1 p-6"
                          >
                            <img
                              src="dist/images/svgs/icon-connect.svg"
                              alt=""
                              width="24"
                              height="24"
                            />
                          </span>
                          <div class="w-75 d-inline-block v-middle ps-3">
                            <h6 class="mb-1 text-primary fw-semibold">
                              Refer a Friend
                            </h6>
                            <span class="d-block text-dark"
                              >Refer to a Friend and Get Bonus Direct</span
                            >
                          </div>
                        </a>
                      </div>
                      <div class="d-grid py-4 px-7 pt-8">
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          @click="logout()"
                        >
                          Log Out
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <!--  Header End -->
      <div class="container-fluid">
        <EmptyProductDisturbingItem v-if="emptyProductItem" />
        <router-view name="dashboard"></router-view>
      </div>
    </div>
    <div class="dark-transparent sidebartoggler"></div>
    <div class="dark-transparent sidebartoggler"></div>
  </div>
  <!--  Shopping Cart -->
  <div
    class="offcanvas offcanvas-end shopping-cart"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header py-4">
      <h5 class="offcanvas-title fs-5 fw-semibold" id="offcanvasRightLabel">
        Shopping Cart
      </h5>
      <a href="/sales" class="btn btn-primary rounded-4 px-3 py-1 lh-sm">New Pos</a>
    </div>
    <div class="offcanvas-body h-100 px-4 pt-0" data-simplebar>
      <ul class="mb-0">
        <li class="pb-7" v-for="(cart, key) in carts" :key="key">
          <div class="d-flex align-items-center">
            <h6 width="95"
              height="75" class="rounded-1 me-9 flex-shrink-0">Order # {{ cart.id }}</h6>
            <div>
              <h6 class="mb-1">{{ cart.name }}</h6>
              <!-- <p class="mb-0 text-muted fs-2">POS</p> -->
              <div
                class="d-flex align-items-center justify-content-between mt-2"
              >
                <h6 class="fs-2 fw-semibold mb-0 text-muted">RWF {{ cart.total_price }}</h6>
                <div class="input-group input-group-sm w-50">
                  <button
                    class="btn border-0 round-20 minus p-0 bg-light-success text-success"
                    type="button"
                    id="add1" @click="
                        minusProductCart(
                          cart.id,
                          cart.name,
                          cart.sales_price,
                          cart.image
                        )
                      "
                  >
                    -
                  </button>
                  <input
                    type="text"
                    class="form-control round-20 bg-transparent text-muted fs-2 border-0 text-center qty"
                    placeholder=""
                    aria-label="Example text with button addon"
                    aria-describedby="add1"
                    :value="cart.quantity"
                  />
                  <button
                    class="btn text-success bg-light-success p-0 round-20 border-0 add"
                    type="button"
                    id="addo2" @click="
                        plusProductCart(
                          cart.id,
                          cart.name,
                          cart.sales_price,
                          cart.image
                        )
                      "
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="text-center my-4" v-if="cartIsempty">
                  <img
                    src="dist/images/products/empty-shopping-bag.gif"
                    alt=""
                    class="img-fluid"
                    width="200"
                  />
                  <h5 class="mb-3 fw-semibold fs-5">Cart is Empty</h5>
                  <!-- <a href="#" class="btn btn-primary">Go back to Shopping</a> -->
                </div>
      <div class="align-bottom">
        <div class="d-flex align-items-center pb-7">
          <span class="text-dark fs-3">Sub Total</span>
          <div class="ms-auto">
            <span class="text-dark fw-semibold fs-3">Rwf {{ subTotal }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center pb-7">
          <span class="text-dark fs-3">Total</span>
          <div class="ms-auto">
            <span class="text-dark fw-semibold fs-3">Rwf {{ subTotal }}</span>
          </div>
        </div>
        <a href="/sales" class="btn btn-outline-primary w-100"
          >Go to shopping cart</a
        >
      </div>
    </div>
  </div>

  <!--  Mobilenavbar -->
  <div
    class="offcanvas offcanvas-start"
    data-bs-scroll="true"
    tabindex="-1"
    id="mobilenavbar"
    aria-labelledby="offcanvasWithBothOptionsLabel"
  >
    <nav class="sidebar-nav scroll-sidebar">
      <div class="offcanvas-header justify-content-between">
        <img src="dist/images/logos/favicon.ico" alt="" class="img-fluid" />
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div
        class="offcanvas-body profile-dropdown mobile-navbar"
        data-simplebar=""
      >
        <ul id="sidebarnav">
          <li class="sidebar-item">
            <a
              class="sidebar-link has-arrow"
              href="javascript:void(0)"
              aria-expanded="false"
            >
              <span>
                <i class="ti ti-apps"></i>
              </span>
              <span class="hide-menu">Apps</span>
            </a>
            <ul aria-expanded="false" class="collapse first-level my-3">
              <li class="sidebar-item py-2">
                <a href="#" class="d-flex align-items-center">
                  <div
                    class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="dist/images/svgs/icon-dd-chat.svg"
                      alt=""
                      class="img-fluid"
                      width="24"
                      height="24"
                    />
                  </div>
                  <div class="d-inline-block">
                    <h6 class="mb-1 bg-hover-primary">Chat Application</h6>
                    <span class="fs-2 d-block fw-normal text-muted"
                      >New messages arrived</span
                    >
                  </div>
                </a>
              </li>
              <li class="sidebar-item py-2">
                <a href="#" class="d-flex align-items-center">
                  <div
                    class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="dist/images/svgs/icon-dd-invoice.svg"
                      alt=""
                      class="img-fluid"
                      width="24"
                      height="24"
                    />
                  </div>
                  <div class="d-inline-block">
                    <h6 class="mb-1 bg-hover-primary">Invoice App</h6>
                    <span class="fs-2 d-block fw-normal text-muted"
                      >Get latest invoice</span
                    >
                  </div>
                </a>
              </li>
              <li class="sidebar-item py-2">
                <a href="#" class="d-flex align-items-center">
                  <div
                    class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="dist/images/svgs/icon-dd-mobile.svg"
                      alt=""
                      class="img-fluid"
                      width="24"
                      height="24"
                    />
                  </div>
                  <div class="d-inline-block">
                    <h6 class="mb-1 bg-hover-primary">Contact Application</h6>
                    <span class="fs-2 d-block fw-normal text-muted"
                      >2 Unsaved Contacts</span
                    >
                  </div>
                </a>
              </li>
              <li class="sidebar-item py-2">
                <a href="#" class="d-flex align-items-center">
                  <div
                    class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="dist/images/svgs/icon-dd-message-box.svg"
                      alt=""
                      class="img-fluid"
                      width="24"
                      height="24"
                    />
                  </div>
                  <div class="d-inline-block">
                    <h6 class="mb-1 bg-hover-primary">Email App</h6>
                    <span class="fs-2 d-block fw-normal text-muted"
                      >Get new emails</span
                    >
                  </div>
                </a>
              </li>
              <li class="sidebar-item py-2">
                <a href="#" class="d-flex align-items-center">
                  <div
                    class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="dist/images/svgs/icon-dd-cart.svg"
                      alt=""
                      class="img-fluid"
                      width="24"
                      height="24"
                    />
                  </div>
                  <div class="d-inline-block">
                    <h6 class="mb-1 bg-hover-primary">User Profile</h6>
                    <span class="fs-2 d-block fw-normal text-muted"
                      >learn more information</span
                    >
                  </div>
                </a>
              </li>
              <li class="sidebar-item py-2">
                <a href="#" class="d-flex align-items-center">
                  <div
                    class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="dist/images/svgs/icon-dd-date.svg"
                      alt=""
                      class="img-fluid"
                      width="24"
                      height="24"
                    />
                  </div>
                  <div class="d-inline-block">
                    <h6 class="mb-1 bg-hover-primary">Calendar App</h6>
                    <span class="fs-2 d-block fw-normal text-muted"
                      >Get dates</span
                    >
                  </div>
                </a>
              </li>
              <li class="sidebar-item py-2">
                <a href="#" class="d-flex align-items-center">
                  <div
                    class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="dist/images/svgs/icon-dd-lifebuoy.svg"
                      alt=""
                      class="img-fluid"
                      width="24"
                      height="24"
                    />
                  </div>
                  <div class="d-inline-block">
                    <h6 class="mb-1 bg-hover-primary">Contact List Table</h6>
                    <span class="fs-2 d-block fw-normal text-muted"
                      >Add new contact</span
                    >
                  </div>
                </a>
              </li>
              <li class="sidebar-item py-2">
                <a href="#" class="d-flex align-items-center">
                  <div
                    class="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="dist/images/svgs/icon-dd-application.svg"
                      alt=""
                      class="img-fluid"
                      width="24"
                      height="24"
                    />
                  </div>
                  <div class="d-inline-block">
                    <h6 class="mb-1 bg-hover-primary">Notes Application</h6>
                    <span class="fs-2 d-block fw-normal text-muted"
                      >To-do and Daily tasks</span
                    >
                  </div>
                </a>
              </li>
              <ul class="px-8 mt-7 mb-4">
                <li class="sidebar-item mb-3">
                  <h5 class="fs-5 fw-semibold">Quick Links</h5>
                </li>
                <li class="sidebar-item py-2">
                  <a class="fw-semibold text-dark" href="#">Pricing Page</a>
                </li>
                <li class="sidebar-item py-2">
                  <a class="fw-semibold text-dark" href="#"
                    >Authentication Design</a
                  >
                </li>
                <li class="sidebar-item py-2">
                  <a class="fw-semibold text-dark" href="#">Register Now</a>
                </li>
                <li class="sidebar-item py-2">
                  <a class="fw-semibold text-dark" href="#">404 Error Page</a>
                </li>
                <li class="sidebar-item py-2">
                  <a class="fw-semibold text-dark" href="#">Notes App</a>
                </li>
                <li class="sidebar-item py-2">
                  <a class="fw-semibold text-dark" href="#">User Application</a>
                </li>
                <li class="sidebar-item py-2">
                  <a class="fw-semibold text-dark" href="#">Account Settings</a>
                </li>
              </ul>
            </ul>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" href="app-chat.html" aria-expanded="false">
              <span>
                <i class="ti ti-message-dots"></i>
              </span>
              <span class="hide-menu">Chat</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a
              class="sidebar-link"
              href="app-calendar.html"
              aria-expanded="false"
            >
              <span>
                <i class="ti ti-calendar"></i>
              </span>
              <span class="hide-menu">Calendar</span>
            </a>
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" href="app-email.html" aria-expanded="false">
              <span>
                <i class="ti ti-mail"></i>
              </span>
              <span class="hide-menu">Email</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <!--  Search Bar -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content rounded-1">
        <div class="modal-header border-bottom">
          <input
            type="search"
            class="form-control fs-3"
            placeholder="Search here"
            id="search"
          />
          <span data-bs-dismiss="modal" class="lh-1 cursor-pointer">
            <i class="ti ti-x fs-5 ms-3"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!--  Customizer -->
  <button class="btn btn-primary p-3 rounded-circle d-flex align-items-center justify-content-center customizer-btn"
    type="button" data-bs-toggle="dropdown" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
    <i class="ti ti-plus fs-7" data-bs-toggle="tooltip" data-bs-placement="top" title="Quick Action"></i>
  </button>
  <div
    class="offcanvas offcanvas-end customizer"
    tabindex="-1"
    id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel"
    data-simplebar=""
  >
    <div
      class="d-flex align-items-center justify-content-between p-3 border-bottom"
    >
      <h4 class="offcanvas-title fw-semibold" id="offcanvasExampleLabel">
        Settings
      </h4>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body p-4">
      <div class="theme-option pb-4">
        <h6 class="fw-semibold fs-4 mb-1">Theme Option</h6>
        <div class="d-flex align-items-center gap-3 my-3">
          <a
            href="javascript:void(0)"
            onclick="toggleTheme('dist/css/style.min.css')"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 light-theme text-dark"
          >
            <i class="ti ti-brightness-up fs-7 text-primary"></i>
            <span class="text-dark">Light</span>
          </a>
          <a
            href="javascript:void(0)"
            onclick="toggleTheme('dist/css/style-dark.min.css')"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 dark-theme text-dark"
          >
            <i class="ti ti-moon fs-7"></i>
            <span class="text-dark">Dark</span>
          </a>
        </div>
      </div>
      <div class="theme-direction pb-4">
        <h6 class="fw-semibold fs-4 mb-1">Theme Direction</h6>
        <div class="d-flex align-items-center gap-3 my-3">
          <a
            href="./index.html"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2"
          >
            <i class="ti ti-text-direction-ltr fs-6 text-primary"></i>
            <span class="text-dark">LTR</span>
          </a>
          <a
            href="rtl/index.html"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2"
          >
            <i class="ti ti-text-direction-rtl fs-6 text-dark"></i>
            <span class="text-dark">RTL</span>
          </a>
        </div>
      </div>
      <div class="theme-colors pb-4">
        <h6 class="fw-semibold fs-4 mb-1">Theme Colors</h6>
        <div class="d-flex align-items-center gap-3 my-3">
          <ul class="list-unstyled mb-0 d-flex gap-3 flex-wrap change-colors">
            <li
              class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center"
            >
              <a
                href="javascript:void(0)"
                class="rounded-circle position-relative d-block customizer-bgcolor skin1-bluetheme-primary active-theme"
                onclick="toggleTheme('dist/css/style.min.css')"
                data-color="blue_theme"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="BLUE_THEME"
                ><i
                  class="ti ti-check text-white d-flex align-items-center justify-content-center fs-5"
                ></i
              ></a>
            </li>
            <li
              class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center"
            >
              <a
                href="javascript:void(0)"
                class="rounded-circle position-relative d-block customizer-bgcolor skin2-aquatheme-primary"
                onclick="toggleTheme('dist/css/style-aqua.min.css')"
                data-color="aqua_theme"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="AQUA_THEME"
                ><i
                  class="ti ti-check text-white d-flex align-items-center justify-content-center fs-5"
                ></i
              ></a>
            </li>
            <li
              class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center"
            >
              <a
                href="javascript:void(0)"
                class="rounded-circle position-relative d-block customizer-bgcolor skin3-purpletheme-primary"
                onclick="toggleTheme('dist/css/style-purple.min.css')"
                data-color="purple_theme"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="PURPLE_THEME"
                ><i
                  class="ti ti-check text-white d-flex align-items-center justify-content-center fs-5"
                ></i
              ></a>
            </li>
            <li
              class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center"
            >
              <a
                href="javascript:void(0)"
                class="rounded-circle position-relative d-block customizer-bgcolor skin4-greentheme-primary"
                onclick="toggleTheme('dist/css/style-green.min.css')"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="GREEN_THEME"
                ><i
                  class="ti ti-check text-white d-flex align-items-center justify-content-center fs-5"
                ></i
              ></a>
            </li>
            <li
              class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center"
            >
              <a
                href="javascript:void(0)"
                class="rounded-circle position-relative d-block customizer-bgcolor skin5-cyantheme-primary"
                onclick="toggleTheme('dist/css/style-cyan.min.css')"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="CYAN_THEME"
                ><i
                  class="ti ti-check text-white d-flex align-items-center justify-content-center fs-5"
                ></i
              ></a>
            </li>
            <li
              class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center"
            >
              <a
                href="javascript:void(0)"
                class="rounded-circle position-relative d-block customizer-bgcolor skin6-orangetheme-primary"
                onclick="toggleTheme('dist/css/style-orange.min.css')"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="ORANGE_THEME"
                ><i
                  class="ti ti-check text-white d-flex align-items-center justify-content-center fs-5"
                ></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="layout-type pb-4">
        <h6 class="fw-semibold fs-4 mb-1">Layout Type</h6>
        <div class="d-flex align-items-center gap-3 my-3">
          <a
            href="./index.html"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2"
          >
            <i class="ti ti-layout-sidebar fs-6 text-primary"></i>
            <span class="text-dark">Vertical</span>
          </a>
          <a
            href="horizontal/index.html"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2"
          >
            <i class="ti ti-layout-navbar fs-6 text-dark"></i>
            <span class="text-dark">Horizontal</span>
          </a>
        </div>
      </div>
      <div class="container-option pb-4">
        <h6 class="fw-semibold fs-4 mb-1">Container Option</h6>
        <div class="d-flex align-items-center gap-3 my-3">
          <a
            href="javascript:void(0)"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 boxed-width text-dark"
          >
            <i class="ti ti-layout-distribute-vertical fs-7 text-primary"></i>
            <span class="text-dark">Boxed</span>
          </a>
          <a
            href="javascript:void(0)"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 full-width text-dark"
          >
            <i class="ti ti-layout-distribute-horizontal fs-7"></i>
            <span class="text-dark">Full</span>
          </a>
        </div>
      </div>
      <div class="sidebar-type pb-4">
        <h6 class="fw-semibold fs-4 mb-1">Sidebar Type</h6>
        <div class="d-flex align-items-center gap-3 my-3">
          <a
            href="javascript:void(0)"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 fullsidebar"
          >
            <i class="ti ti-layout-sidebar-right fs-7"></i>
            <span class="text-dark">Full</span>
          </a>
          <a
            href="javascript:void(0)"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center text-dark sidebartoggler gap-2"
          >
            <i class="ti ti-layout-sidebar fs-7"></i>
            <span class="text-dark">Collapse</span>
          </a>
        </div>
      </div>
      <div class="card-with pb-4">
        <h6 class="fw-semibold fs-4 mb-1">Card With</h6>
        <div class="d-flex align-items-center gap-3 my-3">
          <a
            href="javascript:void(0)"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 text-dark cardborder"
          >
            <i class="ti ti-border-outer fs-7"></i>
            <span class="text-dark">Border</span>
          </a>
          <a
            href="javascript:void(0)"
            class="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 cardshadow"
          >
            <i class="ti ti-border-none fs-7"></i>
            <span class="text-dark">Shadow</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { errorHandlingMixins } from '../helpers/errorHandlingMixins.js'
import { validateTokenErrorHandling } from '../helpers/validateToken.js'
import EmptyProductDisturbingItem from '../components/globalComponent/EmptyProductDisturbingItem.vue'
import Swal from 'sweetalert2'
export default {
  name: 'HomeView',
  helpers: [errorHandlingMixins, validateTokenErrorHandling],
  components: {
    EmptyProductDisturbingItem
  },
  data () {
    return {
      profileVerified: '',
      profileName: '',
      profileImage: '',
      profileEmail: '',
      selectedWarehouse: '',
      selectedWarehouseName: '',
      selectedWarehouseCategoryID: '',
      warehouses: [],
      emptyProductItem: false,
      countCartPurchase: '',
      countCartSales: '0',
      carts: [],
      subTotal: '',
      cartIsempty: false
    }
  },
  methods: {
    getProductCarted () {
      const existingCart = JSON.parse(localStorage.getItem('cart')) || []
      this.carts = existingCart
      if (existingCart.length === 0) {
        this.cartIsempty = true
      }
      this.calculateTotalCarted()
    },
    plusProductCart (productID, productName, productPrice, productImage) {
      this.cartIsempty = false
      const existingCart = JSON.parse(localStorage.getItem('cart')) || []
      const existingItem = existingCart.find(item => item.id === productID)

      if (existingItem) {
        existingItem.quantity++
        // existingItem.price = existingItem.quantity * productPrice
      } else {
        const newItem = {
          id: productID,
          name: productName,
          quantity: 1,
          price: productPrice,
          total_price: productPrice,
          discount: '0',
          image: productImage
        }
        existingCart.push(newItem)
      }
      localStorage.setItem('cart', JSON.stringify(existingCart))
      this.calculateTotalCarted()
      this.getProductCarted()
      // Swal.fire('Product Minus to Cart', productName + ' ' + 'Minus to Cart', 'success')
    },
    minusProductCart (productID, productName, productPrice, productImage) {
      this.cartIsempty = false
      const existingCart = JSON.parse(localStorage.getItem('cart')) || []
      const existingItem = existingCart.find(item => item.id === productID)

      if (existingItem) {
        existingItem.quantity--
        // existingItem.price = existingItem.quantity * productPrice
      } else {
        const newItem = {
          id: productID,
          name: productName,
          quantity: 1,
          price: productPrice,
          total_price: productPrice,
          discount: '0',
          image: productImage
        }
        existingCart.push(newItem)
      }
      localStorage.setItem('cart', JSON.stringify(existingCart))
      this.calculateTotalCarted()
      this.getProductCarted()
      // Swal.fire('Product Minus to Cart', productName + ' ' + 'Minus to Cart', 'success')
    },
    async calculateTotalCarted () {
      const token = localStorage.getItem('token')
      const existingCart = JSON.parse(localStorage.getItem('cart')) || []
      if (existingCart.length === 0) {
        this.subTotal = '0'
        this.vat = '0'
        this.discount = '0'
        return
      }
      const data = {
        products: existingCart
      }
      try {
        const response = await axios.post(
          '/order/calculate', data, {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        this.vat = response.data.totalTaxAmount
        this.discount = response.data.totalDiscount
        this.subTotal = response.data.totalAmount
      } catch (error) {
        if (error.response.data.message === 'Unauthenticated') {
          localStorage.removeItem('token')
          window.location.href = '/login'
        }
        if (error.response.data.message) {
          Swal.fire('Error Happen', error.response.data.message, 'error')
        }
      }
    },
    async getProducts () {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get(
          '/product/warehouse', {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        this.products = response.data.data
        if (this.products.length === 0) {
          this.emptyProductItem = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    async setDefaultBranch (selectedWarehouse) {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.post(
          '/company/warehouse/makeDefault/' + selectedWarehouse, {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        console.log(response.data.message)
        // Swal.fire('Default warehouse branch Switched', response.data.message, 'success')
        this.getDefaultBranch()
        localStorage.removeItem('cart')
        window.location.reload()
      } catch (error) {
        this.showErrorMessage = true
        if (error.response.data.message === 'Unauthenticated') {
          localStorage.removeItem('token')
          window.location.href = '/login'
        }
      }
    },
    async switchDefaultBranch () {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get(
          '/company/warehouse', {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        this.warehouses = response.data
      } catch (error) {
        this.showErrorMessage = true
        if (error.response.data.message === 'Unauthenticated') {
          localStorage.removeItem('token')
          window.location.href = '/login'
        }
      }
    },
    async getDefaultBranch () {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get(
          '/company/warehouse/default', {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        this.switchDefaultBranch()
        this.selectedWarehouse = response.data.id
        this.selectedWarehouseName = response.data.name
        this.selectedWarehouseCategoryID = response.data.warehouse_category_id
        localStorage.setItem('selectedWarehouseCategoryID', this.selectedWarehouseCategoryID)
        localStorage.setItem('warehouseID', this.selectedWarehouse)
      } catch (error) {
        this.showErrorMessage = true
        if (error.response.data.message === 'Unauthenticated') {
          localStorage.removeItem('token')
          window.location.href = '/login'
        }
      }
    },
    getProfileInfo () {
      const verifyProfile = localStorage.getItem('profileVerified')
      this.profileName = localStorage.getItem('profileName')
      this.profileImage = localStorage.getItem('profileImage')
      this.profileEmail = localStorage.getItem('profileEmail')
      const existingCart = JSON.parse(localStorage.getItem('cartPurchase')) || []
      const existingCartSales = JSON.parse(localStorage.getItem('cart')) || []
      if (existingCart.length > 0) {
        this.countCartPurchase = existingCart.length
      } else {
        this.countCartPurchase = ''
      }
      if (existingCartSales.length > 0) {
        this.countCartSales = existingCartSales.length
      } else {
        this.countCartSales = '0'
      }

      if (verifyProfile == null) {
        this.profileVerified = 'Unverified'
      }
      if (verifyProfile === 'Unauthenticated') {
        localStorage.removeItem('token')
        this.$router.push('/login')
      }
      if (verifyProfile !== null) {
        this.profileVerified = 'Verified'
      }
    },
    validateTokenHelper () {
      validateTokenErrorHandling.methods.validateToken()
    },
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('profileVerified')
      localStorage.removeItem('profileEmail')
      localStorage.removeItem('is_onboarded')
      this.$router.push('/login')
    }
  },
  mounted () {
    this.validateTokenHelper()
    this.getProfileInfo()
    this.getDefaultBranch()
    this.getProducts()
    this.getProductCarted()
    if (localStorage.getItem('token') == null) {
      // localStorage.removeItem('token')
      window.location.href = '/'
    }
  }
}
</script>
